import styled from "styled-components";

export const SigUpContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 380px;
        
    h2 {
            margin: 10px 0;

    }
    `;

  